import React from 'react'
import Link from 'gatsby-link';

import './css/infraestrutura.scss';
import Layout from "../components/layout";
import SEO from "../components/seo";
import Consult from "../images/index/solutions_salesforce.svg";

const infraestrutura = () => (
    <Layout>
        <SEO title="Infraestrutura" description="Oferecemos serviços de suporte em servidores locais e em nuvem, tudo feito na medida para o negócio." keywords={[`infraestrutura`, `serviços de suporte`, `servidores nuvem`, `configuração de redes`, `amazon`, `aws`]} />
        <div class="main" role="main">

            <div className="ui-hero hero-lg hero-center hero-svg-layer-2 ui-gradient-sc ui-tilt">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12" data-vertical_center="true" data-vertical_offset="16">
                            <h1 className="heading animate" data-show="fade-in-up-big" data-delay="100">
                                <span className="text-white"> Soluções </span>na Medida  <spam className="text-white">Para Sua Empresa</spam>
                            </h1>
                            <p className="paragraph text-white">Somos especialistas em atender empresas igual a sua! Oferecemos serviços
                                de suporte em servidores locais e em nuvem, tudo feito na medida para o negócio.</p>
                        </div>
                        <div className="col-sm-12 animate" data-show="fade-in-up" data-delay="400">
                            <img src={Consult}
                                 alt="Applify - App Landing Page HTML Template"
                                 data-uhd data-max_width="1000" className="responsive-on-lg img-infra-index"/>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section bg-white">
                <div className="container ui-icon-blocks ui-blocks-h icons-md">
                    <div className="section-heading center">
                        <h2 className="heading text-indigo">
                            Nossas Soluções
                        </h2>
                        <p>
                            Planos personalizado de infraestrutura e benefícios que sua empresa precisam.
                        </p>
                    </div>
                    <div className="row animate" data-show="fade-in">
                        <div className="col-sm-4 ui-icon-block">
                            <div className="icon fa fa-server text-lime"></div>
                            <h5>Análise de Redes Corporativas</h5>
                            <p>
                                Mantenha o foco em sua empresa e a gestão de TI fica por nossa conta.
                            </p>
                        </div>
                        <div className="col-sm-4 ui-icon-block">
                            <div className="icon fa fa-cloud-upload text-pink"></div>
                            <h5>Servidores em Nuvem</h5>
                            <p>
                                Seus dados seguros com baixo custo, monitoramento e agilidade.
                            </p>
                        </div>
                        <div className="col-sm-4 ui-icon-block">
                            <div className="icon fa fa-database text-blue"></div>
                            <h5>Gestão AWS/DigitalOcean</h5>
                            <p>
                                Serviços on-demand, pague somente pelo o que utiliza nas melhores plataformas cloud.
                            </p>
                        </div>
                        <div className="col-sm-4 ui-icon-block">
                            <div className="icon icon-earphones-alt text-cyan"></div>
                            <h5>Suporte Presencial ou Remoto</h5>
                            <p>
                                Temos o suporte ideal para o que a sua empresa precisar, remoto com um atendimento simplificado e
                                presencial para uma análise detalhada ou troca de algum equipamento.
                            </p>
                        </div>
                        <div className="col-sm-4 ui-icon-block">
                            <div className="icon icon-lock text-dark"></div>
                            <h5>Implementação de Proxy e Firewall</h5>
                            <p>
                                Mantenha o acesso aos dados sua empresa seguro e tenha o controle do que acontece.
                            </p>
                        </div>
                        <div className="col-sm-4 ui-icon-block">
                            <div className="icon icon-envelope text-orange"></div>
                            <h5>E-mail Colaborativo</h5>
                            <p>
                                Ferramentas de colaboração dentro de ambientes corporativos são de extrema importância,
                                o e-mail colaborativo pode ajudar a aumentar a produtividade das equipes dentro das empresas.
                            </p>
                        </div>
                    </div>
                    <p>* Para serviços presenciais, consulte-nos a disponibilidade de sua região.</p>
                </div>
            </div>

            <div id="faq" class="section bg-light">
                <div class="container">
                    <div class="section-heading center">
                        <h2 class="heading text-indigo">
                            Perguntas Frequentes
                        </h2>
                    </div>
                    <div class="row accordion-center">
                        <div class="col-md-6">
                            <div class="ui-accordion-panel">
                                <div class="ui-card shadow-sm ui-accordion">
                                    <h6 class="toggle" data-toggle="accordion-one">1. Qual a importância de tercerizar o serviço de infraestrutura da minha empresa?</h6>
                                    <div class="body in" data-accord="accordion-one">
                                        <p>Sabemos que sua empresa precisa de atenção especial para o negócio que realmente ela é voltada.
                                        Pensando nisso, nós da <strong>System Code</strong> gerenciamos a parte de infraestrutura de sua empresa,
                                        para que você possa focar em seus negócios sem se preocupar com a área de TI.</p>
                                    </div>
                                </div>
                                <div class="ui-card shadow-sm ui-accordion">
                                    <h6 class="toggle" data-toggle="accordion-two">2. Tercerizando posso perder o controle da utilização da internet em minha empresa?</h6>
                                    <div class="body" data-accord="accordion-two">
                                        <p>Pelo contrário, oferecemos serviços que mantêm sua internet segura e te deixa no controle do que tudo está acontecendo.</p>
                                    </div>
                                </div>
                                <div class="ui-card shadow-sm ui-accordion">
                                    <h6 class="toggle" data-toggle="accordion-three">3. Como posso reduzir custos tercerizando meu departamento de TI?</h6>
                                    <div class="body" data-accord="accordion-three">
                                        <p>Nós da <strong>System Coda</strong> estamos preparados para implatação de serviços de gestão de dados na nuvem,
                                        serviços que são analisados de acordo com o seu negócio, pagando somente o que você realmente utiliza.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
);

export default infraestrutura
